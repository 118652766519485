import React from "react";
import Lottie from "react-lottie";
import loader from "../../../assets/lotties/page-loader.json";
import styled from "styled-components";

function Loader({w, h}) {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: loader,
        rendererSettings: {},
    };
    return (
        <LoaderBox>
            <Lottie options={defaultOptions} width={w} height={h} />
        </LoaderBox>
    );
}

export default Loader;
const LoaderBox = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
`;
