import Store from "./components/context/Store";
import { BrowserRouter as Router } from "react-router-dom";
import "./assets/css/styles.css";
import MainRouter from "./components/routing/routers/MainRouter";
import { NextUIProvider } from "@nextui-org/react";

const App = () => {
    return (
        <Router>
            <Store>
                <NextUIProvider>
                    <MainRouter />
                </NextUIProvider>
            </Store>
        </Router>
    );
};
export default App;
