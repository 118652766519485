import React, { useState, useContext, useEffect, useRef } from "react";
import styled from "styled-components";
import { COLORS, textStyle } from "../../utils/constants";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { Context } from "../../context/Store";
import LogOut from "../../../assets/icons/logout.svg";

const DrawerMenu = ({ openMenu, itemChange }) => {
    const [activeItem, setActiveItem] = useState(null);
    const [isShrink, setShrink] = useState(true);
    const navigate = useNavigate();
    const location = useLocation();

    const [clockTicking, setClockTicking] = useState(false);

    const handleArrowClick = () => {
        setClockTicking(!clockTicking);
    };

    const {
        state: {
            user_data: { role, selected_event },
        },
    } = useContext(Context);

    const [menuData, setMenuData] = useState([]);
    const [activate, setActivate] = useState("");

    useEffect(() => {
        setMenuData([
            {
                id: 1,
                icon: "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/23-10-2023/events-dashboard.svg",
                name: "Dashboard",
                to: "dashboard/",
                subMenu: [],
            },
            {
                id: 2,
                icon: "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/23-10-2023/events-list.svg",
                name: "Events",
                to: "events/",
                subMenu: [],
            },
            // {
            //     id: 2,
            //     icon: "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/08-11-2023/events-registrations.svg",
            //     name: "Registrations",
            //     to: `registrations/${selected_event?.slug}/${selected_event?.id}/`,
            //     subMenu: [],
            // },
            ...(role.includes("chief")
                ? [
                      //   {
                      //       id: 3,
                      //       icon: "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/06-11-2023/events-staffs.svg",
                      //       name: "Staffs",
                      //       to: "staffs/",
                      //       subMenu: [],
                      //   },
                      {
                          id: 4,
                          icon: "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/23-10-2023/events-promoters.svg",
                          name: "Promoters",
                          to: "promoters/",
                          subMenu: [],
                      },
                      //   {
                      //       id: 5,
                      //       icon: "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/23-10-2023/events-tickets.svg",
                      //       name: "Tickets",
                      //       to: "tickets/",
                      //       subMenu: [],
                      //   },
                      //   {
                      //       id: 6,
                      //       icon: "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/06-11-2023/events-attendance.svg",
                      //       name: "Attendance",
                      //       to: "attendance/",
                      //       subMenu: [
                      //           {
                      //               id: 15,
                      //               icon: "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/06-11-2023/events-attendance.svg",
                      //               name: "Attendees",
                      //               to: `/attendees/${selected_event?.slug}/${selected_event?.id}/`,
                      //               subMenu: [],
                      //           },
                      //       ],
                      //   },
                      //   {
                      //       id: 7,
                      //       icon: "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/30-11-2023/messages.svg",
                      //       name: "Media Messaging",
                      //       to: "media-dashboard/",
                      //       subMenu: [],
                      //   },
                      //   {
                      //       id: 8,
                      //       icon: "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/30-11-2023/certificates.svg",
                      //       name: "Certificates",
                      //       to: "/certificates/",
                      //       subMenu: [],
                      //   },
                      //   {
                      //       id: 9,
                      //       icon: "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/09-12-2023/interest.svg",
                      //       name: "Interest letter",
                      //       to: "/interest-letter/",
                      //       subMenu: [],
                      //   },
                  ]
                : []),

            // {
            //     id: 11,
            //     icon: "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/30-11-2023/broadcast.svg",
            //     name: "Broadcast",
            //     to: "broadcast-group/",
            //     subMenu: [
            //         {
            //             id: 12,
            //             icon: "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/30-11-2023/template.svg",
            //             name: "Group",
            //             to: "broadcast-group/",
            //         },
            //         {
            //             id: 13,
            //             icon: "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/30-11-2023/template.svg",
            //             name: " Template",
            //             to: "template/",
            //         },
            //         {
            //             id: 14,
            //             icon: "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/30-11-2023/template.svg",
            //             name: "Message",
            //             to: "sendmessage/",
            //             // subMenu: [],
            //         },
            //     ],
            // },
            // {
            //     id: 9,
            //     icon: "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/18-01-2024/ticket-builder.svg",
            //     name: "TicketBuilder",
            //     to: "/ticketbuilder/",
            //     subMenu: [],
            // },
            // {
            //     id: 13,
            //     icon: "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/30-11-2023/template.svg",
            //     name: "BroadcastMessage",
            //     to: "sendmessage/",
            //     subMenu: [],
            // },
            // {
            //     id: 15,
            //     icon: "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/06-11-2023/events-attendance.svg",
            //     name: "Attendees",
            //     to: `/attendees/${selected_event?.slug}/${selected_event?.id}/`,
            //     subMenu: [],
            // },
        ]);
    }, [selected_event?.slug, selected_event?.id]);

    const variants = {
        open: { opacity: 1, y: "0%", height: "auto", width: "100%" },
        closed: { opacity: 0, y: "-10%", height: 0, width: "100%" },
    };

    const parentRef = useRef(null);

    return (
        <Container className={isShrink ? "shrink" : ""}>
            <View>
                <Header>
                    <MenuButton onClick={() => setShrink((prev) => !prev)}>
                        <MenuIcon
                            src={
                                openMenu === false
                                    ? require("../../../assets/icons/menu-2.svg")
                                          .default
                                    : require("../../../assets/icons/menu.svg")
                                          .default
                            }
                            alt="Logo"
                        />
                    </MenuButton>
                    <LogoView>
                        <img
                            src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/01-09-2022/Talrop_logo.svg"
                            alt="Logo"
                        />
                    </LogoView>
                    <LogoutDiv
                        onClick={(e) => {
                            e.preventDefault();
                            localStorage.clear();
                            window.location = "/";
                        }}
                    >
                        <LogoutImgDiv>
                            <LogoutImg src={LogOut} alt="Profile" />
                        </LogoutImgDiv>
                        <LogoutText>Logout</LogoutText>
                    </LogoutDiv>
                    <LogoView2>
                        <img
                            src={require("../../../assets/icons/steyp-logo-2.png")}
                            alt="Logo"
                        />
                    </LogoView2>
                </Header>
                <MenuItems
                    onMouseOver={() => setShrink(false)}
                    onMouseLeave={() => setShrink(true)}
                >
                    <ListContainer>
                        {menuData.map((item, index) => {
                            return (
                                <>
                                    {item?.subMenu?.length ? (
                                        <ListItemDiv
                                            key={index}
                                            ref={parentRef}
                                            onClick={(e) => {
                                                setActiveItem(
                                                    activeItem ? "" : item
                                                );
                                                setActivate(item.name);
                                                navigate(item.to);
                                            }}
                                        >
                                            <ItemContainer
                                                className={
                                                    activate === item.name &&
                                                    "active"
                                                }
                                            >
                                                <IconView>
                                                    <Icon>
                                                        <img
                                                            src={item.icon}
                                                            alt="Logo"
                                                        />
                                                    </Icon>
                                                    <IconText
                                                        style={{
                                                            opacity:
                                                                !openMenu &&
                                                                itemChange
                                                                    ? "0"
                                                                    : "1",
                                                        }}
                                                    >
                                                        {item.name}
                                                        <ArrowImg
                                                            clockTicking={
                                                                clockTicking
                                                            }
                                                            onClick={
                                                                handleArrowClick
                                                            }
                                                        >
                                                            <img
                                                                src="https://steyp.com/static/media/arrow.63a68754.svg"
                                                                alt="arrow"
                                                            />
                                                        </ArrowImg>
                                                    </IconText>
                                                </IconView>

                                                <motion.div
                                                    animate={{
                                                        x: 0,
                                                        y: 0,
                                                        scale: 1,
                                                        rotate:
                                                            item.id ===
                                                            activeItem?.id
                                                                ? -90
                                                                : 0,
                                                    }}
                                                    style={{
                                                        height: "max-content",
                                                    }}
                                                >
                                                    <Arrow
                                                        src={
                                                            require("../../../assets/icons/right-arrow.svg")
                                                                .default
                                                        }
                                                        alt="Logo"
                                                        style={{
                                                            display: openMenu
                                                                ? "flex"
                                                                : "none",
                                                        }}
                                                    />
                                                </motion.div>
                                            </ItemContainer>
                                            <motion.div
                                                animate={
                                                    item.id === activeItem?.id
                                                        ? "open"
                                                        : "closed"
                                                }
                                                variants={variants}
                                            >
                                                <TeamList
                                                    className={
                                                        item.id ===
                                                        activeItem?.id
                                                            ? "active"
                                                            : ""
                                                    }
                                                >
                                                    {item.subMenu &&
                                                        item.subMenu.map(
                                                            (
                                                                subItem,
                                                                index
                                                            ) => (
                                                                <TeamListItem
                                                                    key={index}
                                                                    to={
                                                                        subItem.to
                                                                    }
                                                                    onClick={(
                                                                        e
                                                                    ) => {
                                                                        e.stopPropagation();
                                                                        setActiveItem(
                                                                            item
                                                                        );
                                                                    }}
                                                                >
                                                                    <Icon>
                                                                        <img
                                                                            src={
                                                                                subItem.icon
                                                                            }
                                                                            alt="Logo"
                                                                        />
                                                                    </Icon>
                                                                    <ItemText>
                                                                        {
                                                                            subItem.name
                                                                        }
                                                                    </ItemText>
                                                                </TeamListItem>
                                                            )
                                                        )}
                                                </TeamList>
                                            </motion.div>
                                        </ListItemDiv>
                                    ) : (
                                        <ListItemLink
                                            onClick={(e) => {
                                                e.preventDefault();
                                                navigate(item.to);
                                                setActiveItem(null);
                                                setActivate("");
                                            }}
                                            to={item.to}
                                        >
                                            <IconView>
                                                <Icon>
                                                    <img
                                                        src={item.icon}
                                                        alt="Logo"
                                                    />
                                                </Icon>
                                                <IconText
                                                    style={{
                                                        opacity:
                                                            !openMenu &&
                                                            itemChange
                                                                ? "0"
                                                                : "1",
                                                    }}
                                                >
                                                    {item.name}
                                                </IconText>
                                            </IconView>
                                        </ListItemLink>
                                    )}
                                </>
                            );
                        })}
                    </ListContainer>
                </MenuItems>
            </View>

            <TalropLogoContainer>
                <TalropLogo
                    src={
                        isShrink
                            ? require("../../../assets/icons/talrop-logo-2.svg")
                                  .default
                            : require("../../../assets/icons/talrop-logo.svg")
                                  .default
                    }
                    alt="Logo"
                />
            </TalropLogoContainer>
        </Container>
    );
};

const Header = styled.div`
    position: fixed;
    top: 0;
    padding: 11px 16px 10px 16px;
    left: 0;
    background: #fff;
    z-index: 2;
    width: 100%;
    display: flex;
    border-bottom: 1px solid #b7b7b7;
`;
const LogoView = styled.div`
    display: flex;
    overflow-x: hidden;
    width: 35px;
    height: auto;
    img {
        display: block;
        width: 100%;
    }
    @media all and (max-width: 480px) {
        display: none;
    }
`;
const LogoView2 = styled.div`
    display: none;
    width: 35px;
    img {
        width: 100%;
        display: block;
    }
`;
const MenuButton = styled.span`
    width: 35px;
    height: 35px;
    border-radius: 50%;
    border: 1px solid ${COLORS.light[200]};
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: ${COLORS.light.white};
    z-index: 999;
    margin-right: 30px;

    &.open-menu {
        right: 0px;
    }
    &.shrink {
        right: -30px;
        @media all and (max-width: 480px) {
            top: -5px;
        }
        @media all and (max-width: 440px) {
            right: -25px;
        }
        @media all and (max-width: 350px) {
            right: -20px;
        }
    }
`;

const MenuIcon = styled.img`
    width: 18px;
    display: block;
`;
const MenuItems = styled.div`
    overflow: hidden;
    padding-top: 60px;
`;
const View = styled.div`
    padding: 15px;
    overflow-y: scroll;
    min-height: calc(100vh - 65px);
    height: 100%;
    &::-webkit-scrollbar {
        display: none;
    }
`;
const ListContainer = styled.div`
    list-style: none;
`;
const ListItemLink = styled(NavLink)`
    cursor: pointer;
    border-radius: 9px;
    display: flex;
    align-items: center;
    padding: 10px 7px;
    margin-bottom: 10px;
    border-left: 3px solid ${COLORS.light.white};

    &.active {
        background: ${COLORS.talrop.background};
        border-left: 3px solid ${COLORS.steyp.primary};
    }
    &.location {
        justify-content: space-between;
    }
    justify-content: space-between;
`;

const ListItemDiv = styled.div`
    cursor: pointer;

    padding-bottom: 0px;
    border-left: 3px solid ${COLORS.light.white};
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    &.active {
        background: ${COLORS.talrop.background};
        border-left: 3px solid ${COLORS.steyp.primary};
    }
    &.location {
        justify-content: space-between;
    }
    justify-content: space-between;
`;
const Icon = styled.span`
    margin-right: 10px;
    width: 24px;
    height: 24px;
    display: flex;
    img {
        width: 100%;
        display: block;
    }
    &.shrink {
        margin-right: 0px;
    }
`;
const IconText = styled.p`
    padding: 0px;
    margin: 0px;
    ${textStyle.body16}
    display: flex;
    gap: 70px;
    align-items: center;
`;
const ArrowImg = styled.div`
    transform: ${(clockTicking) =>
        clockTicking.clockTicking ? "rotate(90deg)" : "rotate(0deg)"};
    transition: transform 0.3s ease-in-out;

    width: 8px;
    /* height : 18px; */
    img {
        width: 100%;
        display: block;
        transition: transform 0.3s; //
        /* transform:  rotated(90deg); */
    }
`;
const Arrow = styled.img``;
const IconView = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    min-width: 290px;
    &.shrink {
        margin: 0 auto;
    }
`;
const TeamList = styled.div`
    flex-direction: column;
    width: 100%;
    opacity: 0;
    height: auto;
    display: none;
    &.active {
        opacity: 1;
        height: auto;
        display: block;
    }
`;
const TeamListItem = styled(NavLink)`
    cursor: pointer;
    border-radius: 9px;
    align-items: center;
    padding: 10px;
    display: flex;
    justify-content: start;
    border-left: 3px solid ${COLORS.light.white};

    &.active {
        background: ${COLORS.talrop.background};
        border-left: 3px solid ${COLORS.steyp.primary};
    }
    &.location {
        justify-content: space-between;
    }
`;
const ItemText = styled.p`
    ${textStyle.body16}
    padding:0px;
    margin: 0px;
    white-space: nowrap;
`;
const TalropLogoContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${COLORS.steyp.background};
    border-top: 1px solid ${COLORS.steyp.border};
    padding: 10px;
`;
const TalropLogo = styled.img``;
const Container = styled.div`
    background: ${COLORS.light.white};
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    position: fixed;
    left: 0;
    top: 0;
    z-index: 10;
    min-height: 100vh;
    height: 100%;
    border-right: 1px solid ${COLORS.light[300]};
    background: ${COLORS.light.white};
    transition: 0.8s;
    transition-timing-function: cubic-bezier(0.18, 0.89, 0.2, 1.21);
    width: 260px;
    overflow: hidden;
    &.shrink {
        width: 73px;
        @media all and (max-width: 640px) {
            width: 0px;
        }
    }
`;
const ItemContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding: 10px 6px;
    padding-right: 10px;
    border-radius: 9px;
    margin-bottom: 10px;
    align-items: center;
    &.active {
        background: ${COLORS.talrop.background};
        border-left: 3px solid ${COLORS.steyp.primary};
    }
`;

const LogoutDiv = styled.div`
    display: flex;
    cursor: pointer;
    align-items: center;
    margin-left: auto;
`;
const LogoutImgDiv = styled.div`
    margin-right: 5px;
    width: 15px;
    height: 15px;
`;
const LogoutImg = styled.img`
    width: 100%;
    display: block;
`;
const LogoutText = styled.h5`
    font-size: 14px;
    line-height: 24px;
`;

export default DrawerMenu;
