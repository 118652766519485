import React, { createContext, useReducer } from "react";
import Reducer from "./Reducer";

const initialState = {
    user_data: {
        is_verified: false,
        user_name: "",
        access_token: "",
        photo: "",
        role: "",
        selected_event: {},
    },
    event_registration_fields :{
        name:"",
        phone:"",
        email:"",
        whatsapp_number:"",
        company_name:"",
        designation:"",
        company_size:"",
        number_of_branches:"",
        food_preferance:"",
    },
    currentParams: {},
    
};


const Store = ({ children }) => {
    const [state, dispatch] = useReducer(Reducer, initialState);

    return (
        <Context.Provider value={{ state, dispatch }}>
            {children}
        </Context.Provider>
    );
};

export const Context = createContext(initialState);

export default Store;
