import React from "react";

function MainLoader() {
    return (
        <div
            style={{
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <img
                src={
                    "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/26-07-2023/loader.gif"
                }
                alt="Talrop"
            />
        </div>
    );
}

export default MainLoader;
