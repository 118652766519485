import React, { Suspense, lazy } from "react";
import { Route, Routes } from "react-router-dom";
import MainLoader from "../../includes/loaders/MainLoader";
import DrawerMenu from "../../includes/general/DrawerMenu";

const ChiefRoute = lazy(() => import("../routes/ChiefRoute"));

const AppRouter = () => {
    return (
        <>
            <DrawerMenu />
            <Suspense fallback={<MainLoader />}>
                <Routes>
                    <Route path="/*" element={<ChiefRoute />} />
                </Routes>
            </Suspense>
        </>
    );
};

export default AppRouter;
